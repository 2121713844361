<template>
  <div>
    <p-chart
      ref="graph"
      v-bind="{
        ...$attrs, 
      }"
      :type="chartType"
      v-on="{
        ...$listeners
      }"
    />
  </div>
</template>

<script>

export default {
  props: {
    chartType: {
      type: String
    }
  },
  data() {
    return {
      
    }
  },
  created(){
  },
  watch: {
    chartType(){
      console.log(this.$attrs)
    }
  },
  methods: {
    download(filename = 'Graph_bar.png'){
      console.log('====================================================')
      console.log(this.$refs.graph)
      let imageValue = this.$el.querySelector('')
      var downloadLink = document.createElement('a')
      downloadLink.href = imageValue
      downloadLink.download = filename

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }
}
</script>

<style>

</style>