<template>
  <div class="stats-graph-box mb-2 mt-3">
    <div class=" bg-white p-3 border shadow">
      <h4 class="text-dark d-flex justify-content-between">
        {{ title }}
        <div class="actions">
          <PButton @click="downloadPdf" :disabled="generating"  :icon="generating? 'pi pi-spinner pi-spin' : 'pi pi-download'" :label="!iconButton?'Télécharger':''" class="mb-2 p-button-outlined  me-4"/>
          <PSplitButton :label="!iconButton?'Type de courbe':''" :model="items" class="mb-2 p-button-primary"/>
        </div>
      </h4>
      <div class="stats-graph-box-body">
         <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :filename="title+'.pdf'"
            :manual-pagination="true"
            :paginate-elements-by-height="1200"
            :pdf-quality="2"
            pdf-format="a4"
            :pdf-orientation="pdfOrientation"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
              <report-header >
                <template #title>
                  <h4 class="text-dark d-flex mt-2 justify-content-between">
                    {{ title }}
                  </h4>
                </template>
              </report-header>

              <div class="d-force-wrap">
                <slot :chartType="chartType">Le contenu n'est pas défini</slot>
              </div>
            </section>
        </vue-html2pdf>
        <slot :chartType="chartType">Le contenu n'est pas défini</slot>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from '../report/ReportHeader.vue'
export default {
  components: { ReportHeader },
  props: {
    pdfOrientation: {
      type: String,
      default: 'portrait'
    },
    title: {
      type: String
    },
    iconButton: {
      type: String
    }
  },
  data(){
    return {
      chartType: 'bar',
      generating: false,
      items: [
				{
					label: 'Histogramme',
					icon: 'pi pi-chart-bar',
					command: () => {
            this.chartType = 'bar'
					}
				},
				{
					label: 'Courbe',
					icon: 'pi pi-chart-line',
					command: () => {
            this.chartType = 'line'
					}
				},
				{
					label: 'Pie',
					icon: 'pi pi-chart-pie',
					command: () => {
            this.chartType = 'pie'
					}
				},
				{
					label: 'Doughnut',
					icon: 'pi pi-chart-doughnut',
					command: () => {
            this.chartType = 'doughnut'
					}
				},
      ]
    }
  },
  methods: {
    onProgress(e){
      console.log(e)
      this.generating = true
    },
    downloadPdf(){
      this.$refs.html2Pdf.generatePdf()
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      this.generating = false
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        } 
      }).save()
    },
    hasDownloaded(evt){
      this.generating = false
      console.log(evt)
    }
  }
}
</script>

<style>
  .d-force-wrap .row{
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: stretch;
    width: 100%;
    border: none !important;
  }
  .d-force-wrap .col{
    width: 100%;
  }
</style>