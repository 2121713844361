/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="container- pt-5">
      <div class="bg-white p-2">
        <form class="" method="post" accept-charset="utf-8" action="">
          <div class="row p-fluid grid formgrid">
            <div class="field  col-12 col-md-4">
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :manualInput="false"
              />
             
            </div>
            <div class="field col-12 col-md-4">
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :manualInput="false"
                />
            </div>
            <div class="col-12 col-md-4">
              <div class="d-grid gap-2">
                  <button type="submit" class="btn btn-primary btn-lg">Rechercher</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <de-statistic />

    <template v-if="$can('stats.see', 'Azoli')">
      <div class="row">
        <div class="col-12">
          <StatisticGraphBox :iconButton="false" title="Tous les enrégistrés ayant un dossier">
            <template #default="{ }">
              <azoli-statistic-pie-doughnut
                :criteria="'TOUT'"
                :regroupements="'SEXE'"
                :rightCriteria="'NON_ELIGIBLE'"
                :rightRegroupements="'SEXE'"
                :borderClass="'border-info border-start border-2'"
                :dateDeb="dateDeb"
                :dateFin="dateFin"
              />
            </template>
          </StatisticGraphBox>
        </div>
        <div class="col-md-12">
          <StatisticGraphBox :iconButton="false" title="Les enregistrés éligibles">
            <template #default="{ }">
              <azoli-statistic-pie-doughnut
                :criteria="'ELIGIBLE'"
                :borderClass="'border-warning border-start border-2'"
                :regroupements="'SEXE'"
                :dateDeb="dateDeb"
                :dateFin="dateFin"
              />
            </template>
          </StatisticGraphBox>
        </div>
        <div class="col-12">
          <StatisticGraphBox :iconButton="false" title="Les enregistrés provenant des zones vulnérables">
            <template #default="{ }">
              <azoli-statistic-pie-doughnut
                  :criteria="'VULNERABLE'"
                  :borderClass="'border-danger border-start border-2'"
                  :regroupements="'SEXE'"
                  :dateDeb="dateDeb"
                  :dateFin="dateFin"
                />
            </template>
          </StatisticGraphBox>
        </div>
      </div>
      <!-- <div class="stats-section mb-4 mt-3">
        <h4  class="mb-2">Les enregistrés provenant des zones vulnérables</h4>
        <azoli-statistic-pie-doughnut
          :criteria="'VULNERABLE'"
          :borderClass="'border-danger border-start border-2'"
          :regroupements="'SEXE'"
          :dateDeb="dateDeb"
          :dateFin="dateFin"
        />
      </div> -->
      <!-- <div class="stats-section mb-5 mt-3">
        <h4 class="py">Femmes avec enfants</h4>
        <div class="row">
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box 
              :borderClass="'border-success border-start border-2'"
              label="Femmes avec enfants"
              :value="femmesAvecEnfantsCount"/>
          </div>
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :borderClass="'border-success border-start border-2'"
              label="Femmes enceintes"
              :value="femmeEnceinteCount"/>
          </div>
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :borderClass="'border-success border-start border-2'"
              label="Nombre enfants entre 3 mois et 3 ans"
              :value="nombreEnfants3Mois3AnsCount"/>
          </div>
        </div>
      </div>
       -->
      <StatisticGraphBox title="Nombre d’enregistrés par Département">
        <template #default="{ chartType }">
          <div class="row">
            <div class="col">
              <d-chart :chartType="chartType" :height="parDepartementEnregistresChartHeight" :data="parDepartementEnregistresChartData" :options="chartOptions" />
            </div>
          </div>
        </template>
      </StatisticGraphBox>
      
      <StatisticGraphBox title="Nombre d’enregistrés par Commune">
        <template #default="{ chartType }">
          <div class="row">
            <div class="col col-sm-12 col-md-6">
              <d-chart :chartType="chartType" :height="parCommuneEnregistresChartHeight" :data="parCommuneEnregistresChartData" :options="horizontalOptions" />
            </div>
          </div>
        </template>
      </StatisticGraphBox>
      
      <StatisticGraphBox title="Nombre d’enregistrés par AE">
        <template #default="{ chartType }">
          <div class="row">
            <div class="col">
              <d-chart :chartType="chartType" :height="parAeEnregistresChartHeight" :data="parAeEnregistresChartData" :options="horizontalOptions" />
            </div>
          </div>
        </template>
      </StatisticGraphBox>
      <StatisticGraphBox title="Nombre femmes avec enfants entre 3 mois et 3 ans par Département">
        <template #default="{ chartType }">
          <div class="row">
            <div class="col">
              <d-chart :chartType="chartType" :height="200" :data="parDepartementFemmesEtEnfantsEntre3MoisEt3AnsChartData" :options="chartOptions" />
            </div>
          </div>
        </template>
      </StatisticGraphBox>
    </template>
  </div>
</template>



<script>
import { mapActions, mapGetters } from 'vuex'
// import DemandeurEdit from '@/components/espace/admin/demandeur/DemandeurEdit'
import { paginatorMixin } from '@/mixins/paginator-mixin'
import DeStatistic from '../../../components/espace/demandeur/statistic/DeStatistic.vue'
import FicheAzoliReport from '../../../components/report/FicheAzoliReport.vue'
// import { FileService } from '../../../api/file-service'
import AzoliValueBox from '../../../components/statistic/AzoliValueBox.vue'
import StatisticGraphBox from '../../../components/statistic/StatisticGraphBox.vue'
import ExportableTable from '../../../components/common/ExportableTable.vue'
import DChart from '../../../components/chart/DChart.vue'
import  randomColor from 'randomcolor'

import { FILE_UPLOAD_API_URL } from '../../../config/api-config'
import AzoliStatisticPieDoughnut from '../../../components/statistic/AzoliStatisticPieDoughnut.vue'

export default {
  components: {
    DeStatistic,
    // eslint-disable-next-line vue/no-unused-components
    AzoliValueBox,
    AzoliStatisticPieDoughnut,
    StatisticGraphBox,
    // eslint-disable-next-line vue/no-unused-components
    FicheAzoliReport,
    DChart,
    // eslint-disable-next-line vue/no-unused-components
    ExportableTable
  },
  mixins: [paginatorMixin],
  data () {
    return {
      myFiles: [],
      selected: [],
      search: '',
      dateDeb: null,
      dateFin: null,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: 'card', //card, table
      parDepartementMetierAppris: [],
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      horizontalOptions: {
        indexAxis: 'y',
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
          }
        }
      },
      chartOptions: {
        responsive: true,
        hoverMode: 'index',
        stacked: false,
        scales: {
          yAxes: [{
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
          },
          {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'y-axis-2',
              gridLines: {
                  drawOnChartArea: false
              }
          }]
        }
      },
      filterOptions: [
        {
          column: 'nom',
          value: '',
          label: 'Bénéficiaire',
          dropdown: false
        },
        {
          column: 'phone',
          value: '',
          label: 'Téléphone',
          dropdown: false
        },
        {
          column: 'creePar',
          value: '',
          label: 'AE (Nom & prénom)',
          dropdown: false
        },
        {
          column: 'departement',
          key: 'nom',
          valueKey: 'nom',
          value: '',
          label: 'Département',
          dropdown: true,
          url: '/departements'
        },
        {
          column: 'commune',
          key: 'nom',
          valueKey: 'nom',
          value: '',
          label: 'Commune',
          dropdown: true,
          url: '/communes'
        },
         {
          column: 'metier2',
          key: 'libelle',
          valueKey: 'libelle',
          value: '',
          label: 'Metier appris',
          dropdown: true,
          url: '/romes'
        },
        {
          column: 'arrondissement',
          key: 'nom',
          valueKey: 'nom',
          value: '',
          dropdown: true,
          url: '/arrondissements',
          label: 'Arrondissement'
        },
        {
          column: 'ville',
          key: 'nom',
          valueKey: 'nom',
          value: '',
          dropdown: true,
          url: '/villes',
          label: 'Village/Quartier de ville',
        },
        {
          column: 'status',
          key: 'label',
          value: '',
          label: 'Statut du dossier',
          multiple: true,
          valueKey: 'value',
          items: [
            { value: 'NOUVEAU', label: 'Sans dossier' },
            { value: 'EN_COURS', label: 'En cours' },
            { value: 'TERMINE', label: 'Terminé' },
            { value: 'VALIDE', label: 'Validé' },
          ]
        },
        {
          column: 'metier',
          value: '', 
          dropdown: true,
          label: 'Metier'
        },
      ],
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },  
        {
          text: 'Paramètres',
          href: '#'
        },
        {
          text: 'Demandeurs',
          active: true
        }
      ]
    }
  },
  created () {
    this.queryStatistics()
  },
  watch: {
    dossier(val){
      if(val.azoli){
        this.activeDemandeur = {
          ...val,
          ...val.azoli
        }
      }
    },
    dateDeb(){
      this.queryStatistics()
    },
    dateFin(){
      this.queryStatistics()
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'demandeur/demandeur',
      statutDossiers: 'setting/statutDossiers',
      // demandeurs: 'demandeur/demandeurs',
      pagination: 'demandeur/pagination'
    }),
    tableFields () {
      return [
        'index',
        { key: 'matricule', label: 'Matricule' },
        { key: 'nom', label: 'Nom' },
        { key: 'prenom', label: 'Prénom' },
        { key: 'sexe', label: 'Sexe' },
        { key: 'phone', label: 'Téléphone' },
        { key: 'commune.nom', label: 'Commune' },
        { key: 'statut', label: 'statut' },
        'actions',
      ]
    },
    parDepartementEnregistresTableFields () {
      return [
        // 'index',
        { key: 'departement', label: 'Département' },
        { key: 'M', label: 'Hommes' },
        { key: 'F', label: 'Femmes' },
        { key: 'total', label: 'Total' },
      ]
    },
    parCommuneEnregistresTableFields () {
      return [
        // 'index',
        { key: 'commune', label: 'Commune' },
        { key: 'M', label: 'Hommes' },
        { key: 'F', label: 'Femmes' },
        { key: 'total', label: 'Total' },
      ]
    },
    parAeEnregistresTableFields () {
      return [
        // 'index',
        { key: 'ae', label: 'AE' },
        { key: 'M', label: 'Hommes' },
        { key: 'F', label: 'Femmes' },
        { key: 'total', label: 'Total' },
      ]
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableFields () {
      return [
        // 'index',
        { key: 'departement', label: 'Département' },
        { key: 'femmesCount', label: 'Nombre femmes' },
        { key: 'enfantsCount', label: 'Nombre enfants' },
        // { key: 'total', label: 'Total' },
      ]
    },
    parDepartementEnregistresTableData () {
      return this.parDepartementEnregistres
    },
    parDepartementEnregistresChartData () {
      return this.buildChartData(this.parDepartementEnregistresTableData, 'departement')
    },
    parDepartementMetierApprisTableData () {
      return this.parDepartementMetierAppris
    },
    parDepartementMetierApprisChartData () {
      return this.buildChartData(this.parDepartementMetierApprisTableData, 'departement')
    },
    parDepartementEnregistresChartHeight () {
      return 25 * this.parDepartementEnregistresTableData.length
    },
    parCommuneEnregistresChartData () {
      return this.buildChartData(this.parCommuneEnregistresTableData, 'commune')
    },
    parCommuneEnregistresChartHeight () {
      return 23 * this.parCommuneEnregistresTableData.length
    },
    // parDepartementEnregistresChartData () {
    //   return this.buildChartData(this.parDepartementEnregistresTableData, 'departement')
    // },
    parAeEnregistresChartData () {
      return this.buildChartData(this.parAeEnregistresTableData, 'ae')
    },
    parAeEnregistresChartHeight () {
      return 23 * this.parAeEnregistres.length 
    },
    parCommuneEnregistresTableData () {
      return this.parCommuneEnregistres
    },
    parAeEnregistresTableData () {
      return this.parAeEnregistres
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableData () {
      return this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsChartData () {
      const dataTable = this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans
      const keyName = 'departement'
      const data = dataTable.length == 0? dataTable : dataTable.slice(0, dataTable.length-1)
      return {
        labels: data.map((el) => el[keyName]),
        datasets: [
          {
            label: 'Femmes',
            backgroundColor: 'rgba(255, 99, 132, 1)',
            data: data.map((el) => el.femmesCount)
          },
          {
            label: 'Enfants',
            backgroundColor: 'rgba(54, 162, 235, 1)',
            data: data.map((el) => el.enfantsCount)

          },
          {
            label: 'Total',
            backgroundColor: 'rgba(75, 192, 192, 1)',
            data: data.map((el) => el.total)

          }
        ]
      }
    },
    tableData () {
      return this.mxFilteredItems.map(item => {
        return { ...item }
      })
    },
    sexeOptions () {
      return ['M', 'F'].map(item => ({ value: item, text: item }) )
    },
    fileApiUrl(){
      return FILE_UPLOAD_API_URL
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: 'statistic/fetchAzoliStatistics',
      fetchDemandeurs: 'demandeur/fetchDemandeurs',
      createOrUpdateDemandeur: 'demandeur/createOrUpdateDemandeur',
      deleteDemandeur: 'demandeur/deleteDemandeur',
      downloadAzoli: 'demandeur/downloadAzoli',
      fetchDossier: 'demandeur/fetchDossier'
    }),
    buildChartData(dataTable, keyName, chartType=''){

      const generateColor = randomColor;

      let data = dataTable.length == 0? dataTable : dataTable.slice(0, dataTable.length-1)
      const multiColors = [ 'pie', ].includes(chartType)
      return {
        labels: data.map((el) => el[keyName]),
        datasets: [
          {
            label: 'Femmes',
            backgroundColor: !multiColors?'rgba(255, 99, 132, 1)' : data.map(() => generateColor()),
            data: data.map((el) => el.F)
          },
          {
            label: 'Hommes',
            backgroundColor: !multiColors?'rgba(54, 162, 235, 1)' : data.map(() => generateColor()),
            data: data.map((el) => el.M)

          },
          {
            label: 'Total',
            backgroundColor: !multiColors?'rgba(75, 192, 192, 1)' : data.map(() => generateColor())  ,
            data: data.map((el) => el.total)

          }
        ]
      }
    },
    queryStatistics(){
      const dateDeb = this.dateDeb
      const dateFin = this.dateFin

      this.fetchAzoliStatistics({
        criteria: 'TOUT',
        regroupements: 'METIER_DESIRE,SEXE,DEPARTEMENT',
        dateDeb,
        dateFin,
        formatResult: false
      }).then((data) => {
        let summary = {
          femmesCount: 0,
          enfantsCount: 0,
          total: 0
        }
        // const keyName = 'departement'
        let total = 0
        let byDepartementGrouping = {}
        data.map(el => {
          console.log('---------------*-------------*--------------------',el)
          let [ departement, metier, sexe, count ] = el
          if(byDepartementGrouping[departement] != undefined){
            let item = byDepartementGrouping[departement]

            console.log(metier,item[metier])
            byDepartementGrouping[departement] = {
              ...item,
              value: { ...item.value, [metier]: item.value[metier]? { ...item.value[metier], metier, [sexe]: count, total: count + item.value[total] } :  { metier, [metier]: {  [sexe]: count, total: count } } }
            }
          }
          else {
            byDepartementGrouping[departement] = {
              [departement]: departement,
              departement,
              value:{ metier, [metier]: {  [sexe]: count, total: count } }
            }
          }
          // summary.femmesCount += femmesCount
          // summary.enfantsCount += enfantsCount
          summary.total += total
          return byDepartementGrouping
        })
        console.log('=====================================================')
        console.log(byDepartementGrouping)
        this.parDepartementMetierAppris = Object.values(byDepartementGrouping).map(k => ({ ...k, value: this.formatToDataTable(k.value, {
            keyName: 'metier'
        }) }))
        
      })
      
      this.fetchAzoliStatistics({
        criteria: 'TOUT',
        regroupements: 'DEPARTEMENT,SEXE',
        dateDeb,
        dateFin
      }).then((data) => {
        this.parDepartementEnregistres = this.formatToDataTable(data, {
            keyName: 'departement'
        })
      })

      this.fetchAzoliStatistics({
        criteria: 'TOUT',
        regroupements: 'COMMUNE,SEXE',
        dateDeb,
        dateFin,
      }).then((data) => {
        this.parCommuneEnregistres = this.formatToDataTable(data, {
            keyName: 'commune'
        })
      })
      
      this.fetchAzoliStatistics({
        criteria: 'FEMME_AVEC_ENFANT_3_MOIS_3ANS',
        regroupements: 'AUCUN',
        dateDeb,
        dateFin
      }).then((data) => {
        this.femmesAvecEnfantsCount = data[0]
      })
      
      this.fetchAzoliStatistics({
        criteria: 'NOMBRE_FEMME_ET_NOMBRE_ENFANT_3_MOIS_3ANS',
        regroupements: 'DEPARTEMENT',
        formatResult: false,
        dateDeb,
        dateFin
      }).then((data) => {
        console.log('--------------------------------------------------')
        console.log(data)
        let summary = {
          femmesCount: 0,
          enfantsCount: 0,
          total: 0
        }
        const keyName = 'departement'
        let total = 0
        this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans = Object.keys(data).map(k => {
          
          let [ key, femmesCount, enfantsCount ] = data[k]
          summary.femmesCount += femmesCount
          summary.enfantsCount += enfantsCount
          summary.total += total
          return {
            [keyName]: key,
            femmesCount,
            enfantsCount,
            total,
          }
        })

        this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans.push({
            [keyName]: 'TOTAL',
            ...summary
          })
    
      })

      this.fetchAzoliStatistics({
        criteria: 'FEMME_ENCEINTE',
        regroupements: 'AUCUN',
        dateDeb,
        dateFin
      }).then((data) => {
        this.femmeEnceinteCount = data[0]
      })

      this.fetchAzoliStatistics({
        criteria: 'NOMBRE_ENFANT_3_MOIS_3ANS',
        regroupements: 'AUCUN',
        dateDeb,
        dateFin
      }).then((data) => {
        this.nombreEnfants3Mois3AnsCount = data[0]
      })

      this.fetchAzoliStatistics({
        criteria: 'TOUT',
        regroupements: 'AE,SEXE',
        dateDeb,
        dateFin
      }).then((data) => {
        this.parAeEnregistres = this.formatToDataTable(data, {
            keyName: 'ae'
        })
      })

      this.mxInitializePaginator({
        queryModel: 'demandeur',
        search: this.search,
        fetcherMethod: 'demandeur/fetchDemandeurs',
        dataGetter: 'demandeur/demandeurs',
        paginationGetter: 'demandeur/pagination',
        pagination: {
          sortBy: 'id',
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`
        },
        filterOptions: this.filterOptions,
        searchFields: [ 'whatsapp', 'nom', 'prenom', 'phone', 'commune' ]
      })
    },
    formatToDataTable(data, { keyName }){
        let summary = {}
        return Object.keys(data).map(k => {
        
        let { key, M=0, F=0, total=0 } = data[k]
        summary.M += M
        summary.F += F
        summary.total += total
        if(data[k]['TOTAL'] == undefined){
          return {
            [keyName]: key,
            M,
            F,
            total,
          }
        }
        else {
          return {
            [keyName]: 'TOTAL',
            ...summary
          }
        }
      })
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAll(evt){
      if(evt.target.checked){
        this.$refs.table.selectAllRows()
      }
      else {
        this.$refs.table.clearSelected()
      }
    },
    handleFilePondInit() {
      console.log("FilePond has initialized");
    },
    uploadFile(de){
      console.log(de)
      this.$refs[`pond2`].click()
    },
    onProgress(e){
      console.log(e)
    },
    downloadFiche(de){
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id
      }).then(()=>{
        this.$refs.html2Pdf.generatePdf()
      })
    },
    editDemandeur (demandeur) {
      console.log(demandeur)
      this.activeDemandeur = { ...demandeur }
      this.$router.push({ name: 'espace.conseiller.de.edit' })
    },
    showDemandeur(demandeur) {
      console.log(demandeur)
      this.activeDemandeur = { ...demandeur }
      this.$router.push({ name: 'espace.conseiller.de.detail', params: { demandeurId: demandeur.id }})
    },
    remplirFiche(demandeur){
      this.$router.push({ name: "espace.conseiller.azoli.edit" , params: { demandeurId: demandeur.id } })
    },
    async destroyDemandeur (demandeur) {
      console.log(demandeur)
      await this.$dialog.confirm({
        size: 'md',
        centered: true,
        variant: 'success',

        text: 'Voulez-vous vraiment supprimer cet élément?',
        actions: {
          false: {
            text: 'Non',
            variant: 'outline-success'
          },
          true: {
            text: 'Oui',
            variant: 'success',
            handle: async () => {
              this.deleteDemandeur(demandeur)

            },
          },
        },
      })
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    updateOrCreateDemandeur (demandeur) {
      console.log(demandeur)
      this.createOrUpdateDemandeur(demandeur).then(data => {
        console.log(data)
        this.$toast.success("Opération réussie!", {
            position: 'bottom-right',
            duration: 5000
          })
      })
    }
  }
}
</script>

<style>

</style>