<template>
  <div class="container-">
    <div class="pt-2 pb-5">
        <div class="bg-white p-3 border ">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              ref="table"
              responsive="sm"
              :items="tableData">
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { mapActions } from 'vuex'

export default {
  props: {
    tableFields: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
  },
  components: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style>

</style>