<template>
  <div class="row">
    <div class="col-sm-8 col-md-8">
      <div class="row">
        <div class="col-sm-5 col-md-5 mb-3">
          <azoli-value-box style="height: 100%; font-size: 2rem;"  label="Total enrégistrés" :percentage="totalPercentage" :borderClass="borderClass+' border'" :subtitle="subtitle"  :rightSubtitle="rightSubtitle" :value="totalCount" :rightValue="rightTotalCount"/>
        </div>
        <div class="col-sm-7 col-md-7 mb-2">
          <div class="row">
            <div class="col-sm-12 col-md-12 mb-2">
              <azoli-value-box label="Total Femmes" :borderClass="borderClass+' border card-primary'" :percentage="sexeFPercentage" :subtitle="subtitle" :rightSubtitle="rightSubtitle" :value="sexeFCount" :rightValue="rightSexeFCount"/>
            </div>
            <div class="col-sm-12 col-md-12 mb-2">
              <azoli-value-box label="Total Hommes" :borderClass="borderClass+' border'" :percentage="sexeMPercentage" :subtitle="subtitle" :rightSubtitle="rightSubtitle" :value="sexeMCount" :rightValue="rightSexeMCount"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 col-md-4 d-flex justify-content-center mb-2">
      <p-divider layout="vertical"></p-divider>
      <p-chart :type="'pie'" :height="350" :data="chartData" :options="chartOptions"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import DChart from '../chart/DChart.vue'
import AzoliValueBox from './AzoliValueBox.vue'
import randomColor from 'randomcolor'

export default {
  props: {
    label: {
      type: String,
      require: true
    },
    borderClass: {
      type: String
    },
    dateDeb: {
      type: String,
    },
    dateFin: {
      type: String,
    },
    subtitle: {
      type: String,
      require: true
    },
    criteria: {
      type: String,
      require: true
    },
    regroupements: {
      type: String,
      require: true
    },
    rightCriteria: {
      type: String
    },
    rightRegroupements: {
      type: String
    },
    rightLabel: {
      type: String
    },
    rightSubtitle: {
      type: String
    },
  },
  components: {
    AzoliValueBox,
    // DChart
  },
  data() {
    return {
      delay: 5000,
      
			chartOptions: {
        responsive: true,
				legend: {
          labels: {
            fontColor: randomColor()
          }
        }
			},
      options: {
        useEasing: true,
        useGrouping: true,
        separator: '',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      stat: {},
      rightStat: {}
    }
  },
  created() {
   this.loadData()
  },
  watch: {
    dateDeb () {
      this.loadData()
    },
    dateFin () {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters({
    }),
    sexeFCount(){
      return this.stat['F'] || 0
    },
    sexeMCount(){
      return this.stat['M'] || 0
    },
    totalCount(){
      return this.stat['TOTAL'] || 0
    },
    sexeFPercentage(){
      return this.computePercentage(this.sexeFCount, this.percentageDivider)
    },
    sexeMPercentage(){
      return this.computePercentage(this.stat['M'] || 0, this.percentageDivider)
    },
    percentageDivider(){
     return this.stat['TOTAL'] || 1
    },
    totalPercentage(){
      return this.computePercentage(this.totalCount, this.percentageDivider)
    },
    isDual(){
      return this.rightCriteria != null && this.rightRegroupements != null
    },
    rightSexeFCount(){
      return this.rightStat['F'] || 0
    },
    rightSexeMCount(){
      return this.rightStat['M'] || 0
    },
    rightTotalCount(){
      return this.rightStat['TOTAL'] || 0
    },
    chartData() {
      return {
        labels: ['FEMME','HOMME'],
        datasets: [
          {
            data: [this.sexeFCount, this.sexeMCount],
            backgroundColor: [
              "#42A5F5",
              "#FFA726"
            ],
            hoverBackgroundColor: [
              "#64B5F6",
              "#81C784",
              "#FFB74D"
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: 'statistic/fetchAzoliStatistics',
    }),
    computePercentage(value, divider){
      return this.divider == 0? 0 : (value/divider)*100
    },
    loadData(){
       this.fetchAzoliStatistics({
          criteria: this.criteria,
          regroupements: this.regroupements,
          dateDeb: this.dateDeb,
          dateFin: this.dateFin
        }).then((data) => {
          this.stat = data
        })

        if(this.isDual){
          this.fetchAzoliStatistics({
          criteria: this.rightCriteria,
          regroupements: this.rightRegroupements,
          dateDeb: this.dateDeb,
          dateFin: this.dateFin
        }).then((data) => {
          this.rightStat = data
        })
      }
    }
  },
}
</script>